import React from 'react'
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
                <section className='custom-footer'>
            {/* <div className='footer-logo-area'>
                <img src="logo-n.png" alt="Footer Logo" />
            </div> */}
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <div className='footer-inner'>
                            <h3>Contact Us</h3>
                            <p> <span><img src="image/location_on.png" alt="" /></span>B12, D-215, Devsha Business Park,Sector- 63, Noida, UP</p>
                            <p><span><img src="image/call.png" alt="" /></span><a href="tel: 0120- 5058563"> +91-9818017492</a></p>
                            <p><span><img src="image/call.png" alt="" /></span><a href="tel: 0120- 5058563"> +91-120-5058563</a></p>
                            <p><span><img src="image/mail.png" alt="" /></span><a href="mailto:contact@amsysinfo.com">contact@amsysinfo.com</a></p>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='footer-inner'>
                            <h3>Services</h3>
                            <ul>
                            <li>
                                <Link to="/softwaredevelopment">Software Development</Link>
                            </li>
                            <li>
                                <Link to="/Mobileappdevelopment">Mobile App Development</Link>
                            </li>
                            <li><Link to="/CloudSolutions">Cloud Solutions</Link></li>
                            <li><Link to="/cybersecurity">Cyber Security</Link></li>
                            <li><Link to="/datacentersolutions">Data Center Solutions</Link></li>
                            <li><Link to="/erpsolutions">ERP Solutions</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='footer-inner'>
                            <h3>Quick Links</h3>
                            <ul>
                            <li>
                                <Link to="/">Home</Link>
                                </li>
                                <li>
                                <Link to="/about">About us</Link>
                                </li>
                                {/* <li>
                                    <Link to="/ourwork">Our work</Link>
                                </li> */}
                                <li>
                                <Link to="/whatwedo">What we do</Link>
                                </li>
                                <li>
                                    <Link to="/ourwork">Our work</Link>
                                </li>
                                {/* <li><a href="">Blog</a></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='footer-inner'>
                        <Link className='btn-footer' onClick={() => window.location = 'mailto:contact@amsysinfo.com'}>Get in touch</Link>
                          
                        </div>
                    </div>
                </div>
                
            </div>
        </section>

<section className='custm-footer-bottom-main'>
    <div className='container-fluid'>
    <div className='footer-bottom'>
        <div className='row'>
            {/* <div className='col-lg-6'>
                <div className='footer-bottom-content'>
                    <ul>
                        <li>
                            <a href="">Terms of Service</a>
                        </li>
                        <li><a href="">Privacy Policy</a></li>
                        <li><a href="">Compliance</a></li>
                        <li><a href="">Security</a></li>
                        <li><a href="">GDPR</a></li>
                        <li><a href="">Responsible Disclosure</a></li>
                    </ul>
                </div>
            </div> */}
            <div className='col-lg-12'>
                <div className='footer-bottom-content text-center'>
                    <ul>
                        <li>Copyright © 2024 Amsys Infocom.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>
    </div>
  )
}
